import styled, { css } from 'styled-components'

const Title = styled.h1`
  line-height: 1.5;

  ${props =>
    props.margin === 'home' &&
    css`
      padding-top: 2.75rem;
      margin-bottom: 3.5rem;

      @media (max-width: 375px) {
        padding-top: 2rem;
        margin-bottom: 2rem;
      }
    `}

  ${props =>
    props.margin === 'none' &&
    css`
      margin-top: 0;
      margin-bottom: 0;
    `}
`

export default Title
