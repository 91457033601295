import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Content from './Content'
import Logo from './Logo'
import Nav from './Nav'

const Wrapper = styled.header``

const Main = styled.div`
  height: 200px;
  padding-bottom: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 375px) {
    height: 105px;
    padding-bottom: 25px;
  }
`

const Left = styled.div``

const Right = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`

const Header = ({ siteTitle }) => (
  <Wrapper id="header">
    <Content>
      <Main>
        <Left>
          <Link to="/">
            <Logo />
          </Link>
        </Left>
        <Right>
          <Nav align="right" />
        </Right>
      </Main>
    </Content>
  </Wrapper>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
