import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import 'normalize.css'

import Header from './Header'
import theme from '../theme'

import '../fonts/styles.css'

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: 'FFMarkWebProRegular';
    font-size: 16px;
    line-height: 1.65;
    color: ${props => props.theme.colors.primary};
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: color 0.35s ease;
  }

  a:hover {
    color: ${props => props.theme.colors.primary};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: inherit;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    color: ${props => props.theme.colors.primary};
  }

  h1 {
    font-size: 25px;
    margin-top: 0;
    padding-top: 4.5rem;
    margin-bottom: 3rem;
    text-transform: uppercase;
    letter-spacing: 0.2em;

    @media (max-width: 375px) {
      font-size: 19px;
    }
  }

  h2 {
    margin: 0;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 1.75rem;
  }

  ul {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-left: 1.25rem;
  }
`

const Main = styled.main`
  position: relative;
  padding-bottom: 4.5rem;

  &::before {
    content: '';
    display: block;
    height: 100px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: linear-gradient(to bottom, #f5f6f8, #fff);
  }
`

const Layout = ({ children }) => {
  useEffect(() => {
    // import SmoothScroll from 'smooth-scroll'
    import('smooth-scroll').then(({ default: SmoothScroll }) => {
      new SmoothScroll('[data-scroll]')
    })
  }, [])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Header siteTitle={data.site.siteMetadata.title} />
        <Main>{children}</Main>
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
