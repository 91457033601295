import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.nav``

const List = styled.ul`
  margin: 0 0 -9px 0; /* adjust for line height */
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: ${props =>
    props.align === 'left' ? 'flex-start' : 'flex-end'};

  @media (max-width: 640px) {
    display: block;
  }
`

const Item = styled.li`
  text-transform: uppercase;
  font-size: 19px;
  margin-right: 34px;
  letter-spacing: 0.08em;

  &:last-of-type {
    margin-right: 0;
  }
`

const Nav = ({ align = 'left' }) => (
  <Wrapper>
    <List align={align}>
      <Item>Dr. Gerber</Item>
      <Item>Dr. Imperiali</Item>
      <Item>Dr. Matulienė</Item>
    </List>
  </Wrapper>
)

export default Nav
