import React from 'react'
import styled from 'styled-components'

import logo from '../images/breitenrain-logo.svg'

const Img = styled.img`
  display: block;
  width: 304px;
  height: auto;
  max-width: 100%;

  @media (max-width: 375px) {
    width: 226px;
  }
`

const Logo = () => <Img src={logo} alt="Zahnarztpraxis Breitenrain" />

export default Logo
