import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: 100%;
  width: 1332px; /* 1200 */
  margin-left: auto;
  margin-right: auto;
  padding-left: 66px;
  padding-right: 66px;

  @media (max-width: 860px) {
    padding-left: 48px;
    padding-right: 48px;
  }

  @media (max-width: 768px) {
    padding-left: 36px;
    padding-right: 36px;
  }

  @media (max-width: 375px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`

const Content = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
)

export default Content
